import {VerticalTimelineElement} from "react-vertical-timeline-component";
import React from "react";
import Chips from "../common/chips";

export default function Trianz() {
    return (
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{background: '#0070C1', color: '#fff'}}
            contentArrowStyle={{borderRight: '7px solid  rgb(33, 150, 243)'}}
            date="2010 - 2014"
            iconStyle={{background: '#0070C1', color: '#fff'}}
        >
            <h3 className="vertical-timeline-element-title">Senior Software Engineer </h3>
            <h4 className="vertical-timeline-element-subtitle">Bangalore, India</h4>


            <p>
                Trianz
            </p>

            <ul>
                <li>
                    NetApp SmartSolve
                </li>
                <li>
                    Hybrid Mobile Apps for UAE govt
                </li>
            </ul>

            <div className={"expertize"}>
                <Chips>React</Chips>
                <Chips>Angular</Chips>
                <Chips>Backbone</Chips>
                <Chips>Js</Chips>
                <Chips>HTML</Chips>
                <Chips>CSS</Chips>
            </div>

        </VerticalTimelineElement>
    )
}