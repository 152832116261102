import React from "react";
import jag from "../../images/jag.jpeg";

export default function Header(props) {
    return (
        <div className={"header"}>
            <div className={"profile"}>

                <img src={jag}/>
                <h2>
                    Jagadeesha B Y
                </h2>

            </div>

        </div>
    )
}