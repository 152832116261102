import {VerticalTimelineElement} from "react-vertical-timeline-component";
import React from "react";
import Chips from "../common/chips";


export default function AmazonIndia() {
    return (
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{background: 'rgb(222 137 61)', color: '#fff'}}
            contentArrowStyle={{borderRight: '7px solid rgb(222 137 61)'}}
            date="2016 - 2018"
            iconStyle={{background: 'rgb(222 137 61)', color: '#fff'}}
        >
            <h3 className="vertical-timeline-element-title">Web Developement Engineer</h3>
            <h4 className="vertical-timeline-element-subtitle">Bangalore, India</h4>
            <p>
                Amazon associates
            </p>
            <ul>
                <li>
                    Associates website design and development
                </li>
                <li>
                    Associates mobile website design and development
                </li>
            </ul>

            <div className={"expertize"}>
                <Chips>React</Chips>
                <Chips>Angular</Chips>
                <Chips>Backbone</Chips>
                <Chips>Js</Chips>
                <Chips>HTML</Chips>
                <Chips>CSS</Chips>

            </div>


        </VerticalTimelineElement>
    )
}