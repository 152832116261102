import React from 'react';
import './App.css';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {AmazonIndia, AmazonUS, Sapient, Trianz} from "./components/company";
import Footer from "./components/common/footer";
import Header from "./components/common/header";


function App() {
    return (
        <div>
          <Header/>
            {/*<section className={"page-section"}>*/}
            {/*    <h2>About Me</h2>*/}
            {/*</section>*/}


            <section className={"page-section"}>
                {/*<h2>Work Experience</h2>*/}
                <div className="application details">
                    <VerticalTimeline layout={"2-columns"}>
                        <AmazonUS/>
                        <AmazonIndia/>
                        <Sapient/>
                        <Trianz/>
                        <VerticalTimelineElement
                            iconStyle={{background: 'rgb(16, 204, 82)', color: '#fff'}}
                        />
                    </VerticalTimeline>
                </div>
            </section>

            {/*<Footer/>*/}
        </div>
    );
}

export default App;
