import {VerticalTimelineElement} from "react-vertical-timeline-component";
import React from "react";
import Chips from "../common/chips";

export default function Sapient() {
    return (
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{background: '#fe414d', color: '#fff'}}
            contentArrowStyle={{borderRight: '7px solid  #fe414d'}}
            date="2014 - 2016"
            iconStyle={{background: '#fe414d', color: '#fff'}}
        >
            <h3 className="vertical-timeline-element-title">Senior Interactive Developer </h3>
            <h4 className="vertical-timeline-element-subtitle">Bangalore, India</h4>
            <p>
                Sapient
            </p>
            <ul>
                <li>
                    Loblaws
                </li>
                <li>
                    McGraw Hill
                </li>

            </ul>

            <div className={"expertize"}>
                <Chips>React</Chips>
                <Chips>Angular</Chips>
                <Chips>Backbone</Chips>
                <Chips>Js</Chips>
                <Chips>HTML</Chips>
                <Chips>CSS</Chips>

            </div>


        </VerticalTimelineElement>
    )
}