import {VerticalTimelineElement} from "react-vertical-timeline-component";
import React from "react";
import Chips from "../common/chips";

export default function AmazonUS(){
    return (
        <section>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{background: '#232f3e', color: '#fff'}}
                contentArrowStyle={{borderRight: '7px solid  #232f3e'}}
                date="2018 - present"
                iconStyle={{background: '#232f3e', color: '#fff'}}
            >
                <h3 className="vertical-timeline-element-title">Front End Engineer III</h3>
                <h4 className="vertical-timeline-element-subtitle">Seattle, USA</h4>
                <p>
                   Amazon connect
                </p>

                <ul>
                    <li>
                        Amazon connect contact control panel
                    </li>
                    <li>
                        Picasa Component library
                    </li>
                </ul>

                <div className={"expertize"}>
                    <Chips>React</Chips>
                    <Chips>Angular</Chips>
                    <Chips>Js</Chips>
                    <Chips>HTML</Chips>
                    <Chips>CSS</Chips>

                </div>

            </VerticalTimelineElement>
        </section>
    )
}